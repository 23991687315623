import { NextPage } from 'next';
import { defineMessages, useIntl } from 'react-intl';
import { HttpErrorStatusCodes } from 'src/api';
import { ROUTES } from 'src/constants/routes.const';
import { FullPageError } from 'src/components';
import { MemrisePageContext } from 'src/utils/context';
import { convertToMemriseError } from 'src/utils/errors';

type MemriseError = ReturnType<typeof convertToMemriseError>;

type MemriseErrorPageProps = {
  message?: MemriseError['message'];
  statusCode?: MemriseError['httpStatusCode'];
  onDismiss?: () => void;
};

const UNAUTHORIZED_APP_PATHS = [ROUTES.signIn];

const msgs = defineMessages({
  clientError: {
    id: 'error_page_client_error_title',
    defaultMessage: 'An error occurred on client',
  },
  serverError: {
    id: 'error_page_server_error_title',
    defaultMessage: 'A {statusCode} error occurred on the server.',
  },
});

function isUnauthorizedAppPath(path: string) {
  path = path.toLowerCase();

  return UNAUTHORIZED_APP_PATHS.some(pathPrefix => path.startsWith(pathPrefix.toLowerCase()));
}

/**
 * Overwrites the default error page to style it with the memrise header,
 * etc
 */
const MemriseErrorPage: NextPage<MemriseErrorPageProps> = ({ message, statusCode, onDismiss }) => {
  const intl = useIntl();

  const title = statusCode
    ? intl.formatMessage(msgs.serverError, { statusCode })
    : intl.formatMessage(msgs.clientError);

  return <FullPageError detail={message} onDismiss={onDismiss} title={title} />;
};

MemriseErrorPage.getInitialProps = async ({
  asPath,
  err,
  res,
}: MemrisePageContext): Promise<MemriseErrorPageProps> => {
  const { httpStatusCode, message } = convertToMemriseError(
    err || /* istanbul ignore next: untested branch of code, please test */ null,
    res,
  );

  if (
    httpStatusCode === HttpErrorStatusCodes.NOT_FOUND &&
    asPath &&
    isUnauthorizedAppPath(asPath)
  ) {
    const path = asPath.split('?').at(0) as string;
    const lowerCasePath = path.toLowerCase();

    if (path !== lowerCasePath && res) {
      const location = asPath.replace(path, lowerCasePath);
      res.writeHead(301, { location });
      res.end();
    }
  }

  return { message, statusCode: httpStatusCode };
};

export default MemriseErrorPage;
