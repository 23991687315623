import accent from './icons/accent.svg';
import aeroplane from './icons/aeroplane.svg';
import appleButtonIcon from './icons/apple-button-icon.svg';
import arrowDown from './icons/arrow-down.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowRight from './icons/arrow-right.svg';
import blob1 from './icons/blob1.svg';
import burger from './icons/burger.svg';
import burgerThin from './icons/burger-thin.svg';
import caretDown from './icons/caret-down.svg';
import celebration from './icons/celebration.svg';
import checkmark from './icons/checkmark.svg';
import checkmarkNew from './icons/checkmark-new.svg';
import chevronDown from './icons/chevron-down.svg';
import chevronLeft from './icons/chevron-left.svg';
import chevronRight from './icons/chevron-right.svg';
import chevronUp from './icons/chevron-up.svg';
import circle from './icons/circle.svg';
import circleFilled from './icons/circle-filled.svg';
import close from './icons/close.svg';
import connectionError from './icons/connection-error.svg';
import cross from './icons/cross.svg';
import crossNew from './icons/cross-new.svg';
import devTools from './icons/dev-tools.svg';
import difficultWords from './icons/difficult-words.svg';
import difficultWordsFilled from './icons/difficult-words_filled.svg';
import difficultWordsOutline from './icons/difficult-words_outline.svg';
import document from './icons/document.svg';
import dropdownGoal from './icons/dropdownGoal.svg';
import dropdownRecently from './icons/dropdownRecently.svg';
import dropdownTag from './icons/dropdownTag.svg';
import dropdownTeaching from './icons/dropdownTeaching.svg';
import edit from './icons/edit.svg';
import ellipsis from './icons/ellipsis.svg';
import ellipsisNew from './icons/ellipsis-new.svg';
import emptyFlag from './icons/empty-flag.svg';
import expandArrow from './icons/expand-arrow.svg';
import explore_home from './icons/explore_home.svg';
import explore_learn from './icons/explore_learn.svg';
import facebook from './icons/facebook.svg';
import facebookButtonIcon from './icons/facebook-button-icon.svg';
import facebookShareIcon from './icons/facebook-share-icon.svg';
import filter from './icons/filter.svg';
import goal from './icons/goal.svg';
import googleButtonIcon from './icons/google-button-icon-1.svg';
import grid from './icons/grid.svg';
import headphones from './icons/headphones.svg';
import home from './icons/home.svg';
import homeFilled from './icons/home_filled.svg';
import homeOutline from './icons/home_outline.svg';
import ignoreWord from './icons/ignore-word.svg';
import info from './icons/info.svg';
import infoOutline from './icons/info-outline.svg';
import instagram from './icons/instagram.svg';
import keyboard from './icons/keyboard.svg';
import languages from './icons/languages.svg';
import learn from './icons/learn.svg';
import learnWithLocals from './icons/learn-with-locals.svg';
import lightningBoltInCircle from './icons/lightning_bolt_circle.svg';
import link from './icons/link.svg';
import listening from './icons/listening.svg';
import loader from './icons/loader.svg';
import locked from './icons/locked.svg';
import memrise from './icons/memrise.svg';
import memriseBlob from './icons/memrise-blob.svg';
import memriseSmallBlack from './icons/memrise-small-black.svg';
import more from './icons/more.svg';
import padlock from './icons/padlock.svg';
import padlockGradient from './icons/padlock-gradient.svg';
import pause from './icons/pause.svg';
import play from './icons/play.svg';
import play_filled from './icons/play_filled.svg';
import playVideo from './icons/play_video.svg';
import plus from './icons/plus.svg';
import premium from './icons/premium.svg';
import premiumStar from './icons/premium-star.svg';
import profile from './icons/profile.svg';
import profileIcon from './icons/profile-icon.svg';
import question from './icons/question.svg';
import review from './icons/review.svg';
import rocket from './icons/rocket.svg';
import searchThin from './icons/search_thin.svg';
import sentences from './icons/sentences.svg';
import social from './icons/social.svg';
import speedLearnable from './icons/speed-learnable.svg';
import speedReview from './icons/speed-review.svg';
import star from './icons/star.svg';
import tick from './icons/tick.svg';
import tickFilled from './icons/tick_filled.svg';
import tickOutline from './icons/tick_outline.svg';
import twitter from './icons/twitter.svg';
import twitterShareIcon from './icons/twitter-share-icon.svg';
import unlockWhite from './icons/unlock_white.svg';
import unlockOutline from './icons/unlock_outline.svg';
import unlocked from './icons/unlocked.svg';
import wand from './icons/wand.svg';
import warning from './icons/warning.svg';

export default {
  accent,
  aeroplane,
  appleButtonIcon,
  arrowDown,
  arrowLeft,
  arrowRight,
  blob1,
  burger,
  burgerThin,
  caretDown,
  celebration,
  checkmark,
  checkmarkNew,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  circle,
  circleFilled,
  close,
  connectionError,
  cross,
  crossNew,
  devTools,
  difficultWords,
  difficultWordsFilled,
  difficultWordsOutline,
  document,
  dropdownGoal,
  dropdownRecently,
  dropdownTag,
  dropdownTeaching,
  edit,
  ellipsis,
  ellipsisNew,
  emptyFlag,
  expandArrow,
  explore_home,
  explore_learn,
  facebook,
  facebookButtonIcon,
  facebookShareIcon,
  filter,
  goal,
  googleButtonIcon,
  grid,
  headphones,
  home,
  homeFilled,
  homeOutline,
  ignoreWord,
  info,
  infoOutline,
  instagram,
  keyboard,
  languages,
  learn,
  learnWithLocals,
  lightningBoltInCircle,
  link,
  listening,
  loader,
  locked,
  memrise,
  memriseBlob,
  memriseSmallBlack,
  more,
  padlock,
  padlockGradient,
  pause,
  play,
  play_filled,
  playVideo,
  plus,
  premium,
  premiumStar,
  profile,
  profileIcon,
  question,
  review,
  rocket,
  searchThin,
  sentences,
  social,
  speedLearnable,
  speedReview,
  star,
  tick,
  tickFilled,
  tickOutline,
  twitter,
  twitterShareIcon,
  unlockWhite,
  unlockOutline,
  unlocked,
  wand,
  warning,
};
