import styled, { css } from 'styled-components';

export const Error = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: ${theme.sz.ms};
    background-color: ${theme.co.primary99};
    border-radius: ${theme.sz.s4};
    color: ${theme.co.secondary20};
    font-size: ${theme.DEPRECATED_ty.mediumLarge};
    font-weight: ${theme.DEPRECATED_ty.mediumWeight};
    padding: ${theme.sz.s8};
    z-index: ${theme.zi.z7};

    @media (min-width: ${theme.sz.ms}) {
      min-width: ${theme.sz.s32};
    }
  `,
);

export const ErrorTitle = styled.div(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.larger};
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin-bottom: ${theme.sz.s4};
  `,
);

export const ErrorMessage = styled.div(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.medium};
    overflow: hidden;
    margin-bottom: ${theme.sz.s4};
    text-overflow: ellipsis;
  `,
);
