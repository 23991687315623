export enum HttpErrorStatusCodes {
  BAD_REQUEST = 400,
  UNAUTHORISED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
}

export class MemriseApiError extends Error {
  // "name" is part of web standards: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/name
  name: string;
  // these are custom, and are read in various places this is caught in the code, and end up in Sentry
  httpStatusCode: number;
  code: string;
  url: string;

  constructor(httpStatusCode: number, code: string, message: string, url: string) {
    super(message);
    this.name = 'MemriseApiError ' + code;
    this.httpStatusCode = httpStatusCode;
    this.code = code;
    this.url = url;
  }
}
