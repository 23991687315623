import { getCookie, setCookie, checkCookiesAreEnabled } from 'src/cookies';
import { MemriseError } from 'src/utils/errors';

export async function getCsrfToken(baseURL: string): Promise<string> {
  const cookiesAreEnabled = checkCookiesAreEnabled();
  if (!cookiesAreEnabled) {
    throw new MemriseError(
      'You need to enable cookies for this page to work',
      400, // pass 400 so this doesn't go to Sentry
    );
  }

  let csrftoken = getCookie('csrftoken');

  if (!csrftoken) {
    // If the user first landed on a web_client page, they won't have a CSRF
    // token yet, this forces one to be made.
    const ensureCSRFEndpoint = baseURL + '/web/ensure_csrf';

    // Some browsers don't set cookies in AJAX request, so we manually
    // set it from Javascript based on the token in the body of the API call
    const response = await fetch(ensureCSRFEndpoint, {
      credentials: 'same-origin',
    });
    const json = await response.json();
    setCookie('csrftoken', json.csrftoken);

    csrftoken = getCookie('csrftoken');
    if (!csrftoken) {
      throw Error("Couldn't get CSRF cookie set");
    }
  }
  return csrftoken;
}
