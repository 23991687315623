import { UserState, UserAction } from './types';

export const initialState: UserState = {
  error: null,
  loading: true,
  user: null,
};

export const reducer = (_state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'fetchUser:failure':
      return {
        error: action.payload.error,
        loading: false,
        user: null,
      };

    case 'fetchUser:success':
      return {
        error: null,
        loading: false,
        user: action.payload.user,
      };

    case 'reset':
      return initialState;

    default:
      throw new Error('Unsupported action type');
  }
};
