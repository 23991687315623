import MemriseApi from '../MemriseApi';

export default class ScenariosBetaApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async forceJoinScenariosBeta() {
    return this.post(`/path_scenarios_beta/force_join/`, {});
  }
}

export const ScenariosBetaApi = new ScenariosBetaApiClass();
