import React, { FC, ReactNode } from 'react';
import Footer from '../Footer';
import * as S from './styles';

interface PageProps {
  header?: ReactNode;
  footerStyle?: 'none' | 'full';
  children?: ReactNode;
}

const Page: FC<PageProps> = ({ children, header, footerStyle = 'full' }) => {
  return (
    <S.PageContainer>
      {header}
      <S.PageContent>{children}</S.PageContent>
      {footerStyle !== 'none' && <Footer />}
    </S.PageContainer>
  );
};

export default Page;
