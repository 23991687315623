export class ParseIntError extends Error {}

export const safeParseInt = (s: string): number | ParseIntError => {
  const parsed = parseInt(s);

  if (Number.isNaN(parsed)) {
    return new ParseIntError(`${s} cannot be parsed to an integer`);
  }

  return parsed;
};

/** Returns a Real Number between the min and max */
export const randomNumber = ({ min, max }: { min: number; max: number }) =>
  Math.random() * (max - min) + min;

/** Returns an integer between the min and max (exclusive) */
export const randomInteger = ({ min = 0, max }: { min?: number; max: number }) =>
  Math.floor(Math.random() * (max - min) + min);

/** Returns a random value out of -1 and 1 */
export const randomSign = () => Math.floor(randomNumber({ min: 0, max: 2 })) * 2 - 1;
