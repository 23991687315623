// The following code is style-only so regressions will not be caught by a Jest unit test
/* istanbul ignore file */

import styled, { css } from 'styled-components';
import { Space } from 'src/utils/theme';

export type Direction = 'horizontal' | 'vertical';

export interface StackProps {
  $direction?: Direction;
  /**
   * Size of gap between children
   */
  $spacing?: Space;

  $paddingX?: Space;
  $paddingY?: Space;

  /**
   * The point at which to spread out child elements using
   * `margin: auto`
   *
   * Note that this is dependent on having a parent element
   * whose size is specified.
   */
  $splitIndex?: number;

  /**
   * When the width/height is restricted, should the component
   * wrap the children
   */
  $shouldWrap?: boolean;

  /**
   * Controls how to align items of different sizes.
   */
  $align?: 'stretch' | 'center' | 'baseline';

  /**
   * Defines how the browser distributes space between and around content items.
   */
  $justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
}

// Map align prop to CSS flex keywords
const alignMap = {
  stretch: 'stretch',
  center: 'center',
  baseline: 'baseline',
};

export const Stack = styled.div<StackProps>(
  ({
    theme,
    $direction = 'vertical',
    $spacing = 's4',
    $splitIndex,
    $paddingX = 's0',
    $paddingY = 's0',
    $shouldWrap = false,
    $align = 'stretch',
    $justify = 'flex-start',
  }) => {
    return css`
      align-items: ${alignMap[$align]};
      display: flex;
      flex-direction: ${$direction === 'vertical' ? 'column' : 'row'};
      flex-wrap: ${$shouldWrap ? 'wrap' : 'nowrap'};
      justify-content: ${$justify};
      padding: ${theme.sz[$paddingY]} ${theme.sz[$paddingX]};

      &:only-child {
        ${$direction === 'vertical' ? 'height' : 'width'}: 100%;
      }

      & > * {
        ${$direction === 'vertical' ? 'margin-top' : 'margin-left'}: 0;
        ${$direction === 'vertical' ? 'margin-bottom' : 'margin-right'}: 0;
      }

      & > * + * {
        ${$direction === 'vertical'
          ? `margin-top: ${theme.sz[$spacing]}`
          : `margin-left: ${theme.sz[$spacing]}`};
        margin-bottom: 0; /* TODO remove this when global typography styles are removed */
      }

      ${typeof $splitIndex !== 'undefined' &&
      `& > *:nth-child(${$splitIndex + 1}) {
          margin-${$direction === 'vertical' ? 'top' : 'left'}: auto;
      }`}
    `;
  },
);

export default Stack;
