import { toCamelCaseDeep } from 'src/utils/transform';
import MemriseApi from '../MemriseApi';
import * as Types from './types';
import * as Schema from './schema';

export default class SettingsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me/settings' });
  }

  public async getLearningSettings(): Promise<Types.GetLearningSettingsResponse> {
    const response = await this.get<Schema.GetLearningSettingsResponse>('/learning/');
    return toCamelCaseDeep(response);
  }

  public async saveLearningSettings(
    params: Schema.SaveLearningSettingsRequest,
  ): Promise<Schema.SaveLearningSettingsResponse> {
    return this.post('/learning/', {
      bodyObject: params,
    });
  }
}

export const SettingsApi = new SettingsApiClass();
