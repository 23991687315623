import { ActionFetchUserFailure, ActionFetchUserSuccess, ActionReset, CompactUser } from './types';

export const fetchUserFailure = (error: Error): ActionFetchUserFailure => ({
  type: 'fetchUser:failure',
  payload: { error },
});

export const fetchUserSuccess = (user: CompactUser): ActionFetchUserSuccess => ({
  type: 'fetchUser:success',
  payload: { user },
});

export const reset = (): ActionReset => ({
  type: 'reset',
});
