import { RuleSet, css } from 'styled-components';

type FontFamily = string;
type FontWeight = number;
type FontSize = number;
type LineHeight = number;
type Args = [FontWeight, FontSize, LineHeight];

const fontFamily = {
  boing: "'Boing', 'Noto', 'Noto Sans JP', 'Open Sans', sans-serif" as const,
  openSans: "'Open Sans', sans-serif" as const,
};

const fontWeight = {
  medium: 500 as const,
  bold: 700 as const,
  extraBold: 800 as const,
};

const createPreset = (
  fontFamily: FontFamily,
  fontWeight: FontWeight,
  fontSize: FontSize,
  lineHeight: LineHeight,
): RuleSet<object> => {
  return css`
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: ${fontWeight};
    font-size: ${fontSize}rem;
    line-height: ${lineHeight};
  `;
};

const createBoingPreset = (...args: Args) => {
  return createPreset(fontFamily.boing, ...args);
};

const createOpenSansPreset = (...args: Args) => {
  return createPreset(fontFamily.openSans, ...args);
};

const typography = Object.freeze({
  button: {
    /** Open Sans, normal, 700, 18px, 27px */
    l: createOpenSansPreset(fontWeight.bold, 1.125, 1.5),

    /** Open Sans, normal, 700, 14px, 21px */
    s: createOpenSansPreset(fontWeight.bold, 0.875, 1.5),
  },

  heading: {
    desktop: {
      accent: {
        /** Boing, normal, 700, 56px, 70px */
        xxl: createBoingPreset(fontWeight.bold, 3.5, 1.25),

        /** Boing, normal, 700, 48px, 60px */
        xl: createBoingPreset(fontWeight.bold, 3, 1.25),
      },

      /** Open Sans, normal, 800, 56px, 70px */
      xxl: createOpenSansPreset(fontWeight.extraBold, 3.5, 1.25),

      /** Open Sans, normal, 700, 48px, 60px */
      xl: createOpenSansPreset(fontWeight.bold, 3, 1.25),

      /** Open Sans, normal, 700, 40px, 50px */
      l: createOpenSansPreset(fontWeight.bold, 2.5, 1.25),

      /** Open Sans, normal, 700, 32px, 40px */
      m: createOpenSansPreset(fontWeight.bold, 2, 1.25),

      /** Open Sans, normal, 700, 24px, 30px */
      s: createOpenSansPreset(fontWeight.bold, 1.5, 1.25),

      /** Open Sans, normal, 700, 20px, 30px */
      xs: createOpenSansPreset(fontWeight.bold, 1.25, 1.5),

      /** Open Sans, normal, 700, 16px, 24px */
      xxs: createOpenSansPreset(fontWeight.bold, 1, 1.5),

      /** Open Sans, normal, 700, 14px, 21px */
      xxxs: createOpenSansPreset(fontWeight.bold, 0.875, 1.5),
    },

    mobile: {
      accent: {
        /** Boing, normal, 700, 40px, 50px */
        xxl: createBoingPreset(fontWeight.bold, 2.5, 1.25),

        /** Boing, normal, 700, 32px, 40px */
        xl: createBoingPreset(fontWeight.bold, 2, 1.25),
      },

      /** Open Sans, normal, 800, 48px, 60px */
      xxl: createOpenSansPreset(fontWeight.extraBold, 3, 1.25),

      /** Open Sans, normal, 700, 40px, 50px */
      xl: createOpenSansPreset(fontWeight.bold, 2.5, 1.25),

      /** Open Sans, normal, 700, 32px, 40px */
      l: createOpenSansPreset(fontWeight.bold, 2, 1.25),

      /** Open Sans, normal, 700, 24px, 30px */
      m: createOpenSansPreset(fontWeight.bold, 1.5, 1.25),

      /** Open Sans, normal, 700, 20px, 30px */
      s: createOpenSansPreset(fontWeight.bold, 1.25, 1.5),

      /** Open Sans, normal, 700, 16px, 24px */
      xs: createOpenSansPreset(fontWeight.bold, 1, 1.5),

      /** Open Sans, normal, 700, 14px, 21px */
      xxs: createOpenSansPreset(fontWeight.bold, 0.875, 1.5),

      /** Open Sans, normal, 700, 12px, 18px */
      xxxs: createOpenSansPreset(fontWeight.bold, 0.75, 1.5),
    },
  },

  paragraph: {
    /** Open Sans, normal, 500, 20px, 30px */
    l: createOpenSansPreset(fontWeight.medium, 1.25, 1.5),

    /** Open Sans, normal, 500, 16px, 24px */
    base: createOpenSansPreset(fontWeight.medium, 1, 1.5),

    /** Open Sans, normal, 500, 14px, 21px */
    m: createOpenSansPreset(fontWeight.medium, 0.875, 1.5),

    /** Open Sans, normal, 500, 12px, 18px */
    s: createOpenSansPreset(fontWeight.medium, 0.75, 1.5),
  },
});

export default typography;
