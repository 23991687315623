/*
 * This file is used to define shared types across the whole domain, which would otherwise be duplicated
 */

export enum SessionType {
  LEARN = 'learn',
  REVIEW = 'classic_review',
  REVIEW_ALEX = 'review',
  SPEED_REVIEW = 'speed_review',
  DIFFICULT_WORDS = 'difficult_words',
  AUDIO = 'listening_skills',
  VIDEO = 'video',
  PRO_CHAT = 'pro_chat',
  GRAMMAR_CHAT = 'grammar_chat',
  CATEGORY_REVIEW = 'category_review',
  PREVIEW = 'preview',
}

/**
 * The session types we support on web_client from a product perspective
 */
export type WebSessionType = Exclude<SessionType, 'pro_chat' | 'grammar_chat'>;

/**
 * Session types that can appear in the single continue
 * button and/or mode selector buttons
 */
export type SuggestableSessionType = Exclude<WebSessionType, 'preview' | 'category_review'>;

export type ProSessionType = Exclude<
  SuggestableSessionType,
  'learn' | 'speed_review' | 'review' | 'classic_review' | 'category_review'
>;

/**
 * Learning sessions types which have 'standard' UI and functionality
 * such that they do not need to be handled by separate components
 * (as is the case with SpeedReview and others)
 */
export type StandardSessionType = Exclude<WebSessionType, 'speed_review' | 'preview'>;

export interface NextSession {
  singleContinue?: {
    sessionType: SuggestableSessionType;
    isProMode?: boolean;
    url: string | null;
    unlockedState: UnlockedState;
  };
  modeSelector?: ModeSelectorSessionOptions;
}

export type UnlockedState = 'AlwaysUnlocked' | 'TemporarilyUnlocked' | 'Locked';

export interface ModeSelectorSessionOption {
  url: string;

  /** Whether to grey out and disable the button */
  isEnabled: boolean;

  /** How many items to review for this session type */
  badgeCount?: number;

  /**
   * Whether to show padlocks and whether the mode is temporarily
   * unlocked (offering a sample session to the user).
   *
   * Can cause an upsell modal to be triggered when clicked so
   * does not disable the button completely.
   */
  unlockedState: UnlockedState;
}

export type ModeSelectorSessionOptions = Record<SuggestableSessionType, ModeSelectorSessionOption>;

export type DailyGoalWordsValue = 5 | 15 | 30;
