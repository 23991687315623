import React, { FC, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import * as S from './styles';
import SwooshSvg from './footer-swoosh.svg';

const msgs = defineMessages({
  termsOfUse: {
    id: 'footer_link_terms',
    defaultMessage: 'Terms of Use',
  },
  privacyPolicy: {
    id: 'footer_link_privacy',
    defaultMessage: 'Privacy Policy',
  },
  cookiePolicy: {
    id: 'footer_link_cookies',
    defaultMessage: 'Cookie Policy',
  },
});

interface LinkProps {
  href: string;
  children?: ReactNode;
}
const TermsLink = ({ href, children }: LinkProps) => (
  <S.TermsLinkContainer>
    <S.ContainedLink href={href}>{children}</S.ContainedLink>
  </S.TermsLinkContainer>
);
const Footer: FC = props => {
  const intl = useIntl();
  return (
    <S.MarginAdjuster {...props}>
      <S.Swoosh Svg={SwooshSvg} />
      <S.FooterRoot data-testid="footer">
        <S.FooterContainer>
          <S.AllLinks>
            <S.Row>
              <TermsLink href="https://www.memrise.com/terms">
                {intl.formatMessage(msgs.termsOfUse)}
              </TermsLink>
              <TermsLink href="https://www.memrise.com/privacy">
                {intl.formatMessage(msgs.privacyPolicy)}
              </TermsLink>
              <TermsLink href="https://www.memrise.com/cookies">
                {intl.formatMessage(msgs.cookiePolicy)}
              </TermsLink>
            </S.Row>
          </S.AllLinks>
        </S.FooterContainer>
      </S.FooterRoot>
    </S.MarginAdjuster>
  );
};

export default Footer;
