import getConfig from 'next/config';
import { isServerSide } from 'src/utils/env/isServerSide';
import MemriseApi from '../MemriseApi';
import { csrfHeaderObj, makeBaseURL } from '../memriseApiFetch';
import {
  AccessTokenParams,
  AccessTokenResponse,
  AppleBody,
  AppleResponse,
  FacebookBody,
  FacebookResponse,
  GoogleBody,
  GoogleResponse,
  SignUpBody,
  SignUpResponse,
  WebParams,
  WebResponse,
} from './schemas';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { OAUTH_CLIENT_ID } = publicRuntimeConfig;

export default class AuthApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/auth' });
  }

  /**
   * Retrieves an access token for a new user
-  * Checks if the user email already exists and saves a
   * user with the given username and password
   *
   * https://docs.memrise.team/reference/rest-api-endpoints/authentication.html#post--v(version)-auth-signup
   */
  public async accessToken(
    // we always know client_id and grant_type so ignore
    params: Omit<AccessTokenParams, 'client_id' | 'grant_type'>,
  ): Promise<AccessTokenResponse> {
    return this.post<AccessTokenResponse, AccessTokenParams>(`/access_token/`, {
      bodyObject: {
        ...params,
        grant_type: 'password',
        client_id: OAUTH_CLIENT_ID,
      },
    });
  }

  /**
   * Retrieves an access token for a new user
-  * Checks if the user email already exists and saves a
   * user with the given username and password
   *
   * https://docs.memrise.team/reference/rest-api-endpoints/authentication.html#post--v(version)-auth-signup
   */
  public async signUp(
    // we always know client_id and grant_type so ignore
    params: Omit<SignUpBody, 'client_id' | 'grant_type'>,
  ): Promise<SignUpResponse> {
    return this.post<SignUpResponse, SignUpBody>(`/signup/`, {
      bodyObject: {
        ...params,
        grant_type: 'password',
        client_id: OAUTH_CLIENT_ID,
      },
    });
  }

  /**
   * Retrieves an access token for a new user using the
   * FacebookOAuth2 social auth backend
   *
   * Updates their timezone if their account is active
   */
  public async facebook(params: Omit<FacebookBody, 'client_id'>): Promise<FacebookResponse> {
    return this.post<FacebookResponse, FacebookBody>(`/facebook/`, {
      bodyObject: {
        ...params,
        client_id: OAUTH_CLIENT_ID,
      },
    });
  }

  /**
   * Retrieves an access token for a new user using Google
   * social auth backend
   */
  public async google(params: Omit<GoogleBody, 'client_id'>): Promise<GoogleResponse> {
    return this.post<GoogleResponse, GoogleBody>(`/google/`, {
      bodyObject: {
        ...params,
        client_id: OAUTH_CLIENT_ID,
      },
    });
  }

  /**
   * Retrieves an access token for a new user using Apple
   * social auth backend
   */
  public async apple(params: Omit<AppleBody, 'client_id'>): Promise<AppleResponse> {
    return this.post<AppleResponse, AppleBody>(`/apple/`, {
      bodyObject: {
        ...params,
        client_id: OAUTH_CLIENT_ID,
      },
    });
  }

  // This is the standard Django logout func to clear the cookie; it's not a mobile REST API
  public async logout(): Promise<void> {
    const hostServerSide = publicRuntimeConfig.MEMRISE_API_HOST;
    const baseURL = makeBaseURL(hostServerSide);
    const method = 'POST';

    await fetch(`/logout/`, {
      method,
      headers: {
        ...(await csrfHeaderObj(baseURL, method, isServerSide())),
      },
    });
  }

  /**
   * Pass a token from the other endpoints to log the user
   * in and redirect to a chosen page
   */
  public async web(params: WebParams): Promise<WebResponse> {
    return this.get<WebResponse, WebParams>(`/web/`, params);
  }
}

export const AuthApi = new AuthApiClass();
