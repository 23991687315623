type QueryParams = Record<string, unknown>;

export const stringify = (
  params: { [index: string]: string | number | [] } | null | undefined | QueryParams,
) => {
  if (params === undefined || params === null) return '';

  const searchParams = new URLSearchParams();
  const sortedParams = Object.keys(params).sort();

  for (const key of sortedParams) {
    const value = params[key];

    if (value !== undefined && value !== null && value !== '') {
      if (Array.isArray(value)) {
        value.forEach(item => searchParams.append(key, item.toString()));
      } else {
        searchParams.append(key, value.toString());
      }
    }
  }

  return searchParams.toString();
};

export const stringifyUrl = (
  url: string,
  params: { [index: string]: string | number } | null | undefined,
) => {
  if (params === undefined || params === null) {
    return url;
  }

  const searchParams = new URLSearchParams();
  const sortedParams = Object.keys(params).sort();

  for (const key of sortedParams) {
    const value = params[key];

    if (value !== undefined && value !== null && value !== '') {
      searchParams.append(key, value.toString());
    }
  }

  const queryString = searchParams.toString();
  if (queryString) {
    return `${url}?${queryString}`;
  }

  return url;
};
