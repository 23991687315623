import { MemriseError } from 'src/utils/errors';
import {
  Learnable,
  LearnableProgress,
  NextSession,
  SessionSourceInfo,
  SessionSourceType,
  SessionType,
  SummaryStats,
} from 'src/api/learningSessions/types';
import MemriseApi from '../MemriseApi';
import {
  fromApiLearningSessionEndResponse,
  fromApiLearningSessionResponse,
  toApiSessionType,
} from './conversion';
import * as Schema from './schema';

/**
 * The response from /learning_sessions/:session_type, converted
 * for the domain types of web_client
 */
export interface LearningSessionsDomainResponse {
  learnables: Learnable[];
  progress: LearnableProgress[];
  sessionSourceInfo: SessionSourceInfo;
  settings: {
    disableMultimedia: boolean;
    disableTapping: boolean;
    prioritizeTyping: boolean;
  };
}

/**
 * The response from /learning_sessions/:session_type, converted
 * for the domain types of web_client
 */
export interface LearningSessionsEndDomainResponse {
  summaryStats: SummaryStats;
  nextSession: NextSession;
}

class LearningSessionsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/learning_sessions' });
  }

  public async startLearningSession(
    sessionType: SessionType,
    {
      sessionSourceType,
      sessionSourceId,
      levelIndex,
    }: {
      sessionSourceType: SessionSourceType;
      sessionSourceId: number;
      levelIndex?: number;
    },
  ): Promise<LearningSessionsDomainResponse> {
    if (sessionType === SessionType.PREVIEW && !levelIndex && sessionSourceType !== 'scenario') {
      throw new MemriseError(
        'LearningSessionsApi with Preview can only be called with a LevelId/LevelIndex & CourseId',
      );
    }

    const apiSessionType = toApiSessionType(sessionType);

    const snakeParams = {
      session_source_id: sessionSourceId,
      session_source_type: sessionSourceType,
      ...(levelIndex &&
        sessionType !== SessionType.CATEGORY_REVIEW && {
          session_source_sub_index: levelIndex,
          session_source_type: 'course_id_and_level_index',
        }),
    };

    const res = await this.post<Schema.LearningSessionsResponse, Schema.LearningSessionParams>(
      `/${apiSessionType}/`,
      {
        bodyObject: snakeParams,
      },
    );

    return fromApiLearningSessionResponse(res);
  }

  public async end(
    sessionType: SessionType,
    sessionSourceType: SessionSourceType,
    sessionPoints: number,
    {
      sessionSourceId,
      levelIndex,
    }: {
      sessionSourceId: number;
      levelIndex?: number;
    },
  ): Promise<LearningSessionsEndDomainResponse> {
    const apiSessionType = toApiSessionType(sessionType);

    if (levelIndex != null) {
      sessionSourceType = 'course_id_and_level_index';
    }

    const snakeParams = {
      session_points: sessionPoints,
      session_type: apiSessionType,
      session_source_type: sessionSourceType,
      session_source_id: sessionSourceId,
      ...(levelIndex != null && {
        session_source_sub_index: levelIndex,
      }),
    };

    const res = await this.post<
      Schema.LearningSessionsEndResponse,
      Schema.LearningSessionsEndParams
    >(`/end/`, {
      bodyObject: snakeParams,
    });

    return fromApiLearningSessionEndResponse(res);
  }
}

export const LearningSessionsApi = new LearningSessionsApiClass();
