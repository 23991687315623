import Router from 'next/router';
import { ParseIntError, safeParseInt } from 'src/utils/numbers';
import { ROUTES } from '../constants/routes.const';
import { MemrisePageContext } from './context';

// We can't test changing browser location unit tests
/* istanbul ignore next */
export const redirect = async (url: string, ctx: MemrisePageContext) => {
  if (ctx.res) {
    ctx.res.writeHead(302, {
      Location: url,
    });
    ctx.res.end();
  } else {
    await Router.push(url);
  }
};

export const makeUrlRelativeOrDashboard = (url: string): string => {
  const dashboard = ROUTES.dashboard.old;
  if (!url) return dashboard;
  try {
    const fullUrl = new URL(url, window.location.href);

    if (fullUrl.hostname !== window.location.hostname) {
      return dashboard;
    }
    return fullUrl.pathname + fullUrl.search;
  } catch (e) {
    return dashboard;
  }
};

export const isCourseRedirect = (urlParam: string) => {
  if (!urlParam.startsWith('/community/course/')) {
    return false;
  }

  const split = urlParam.split('/');
  const [, , , courseIdMatch, , levelMatch] = split;

  if (safeParseInt(courseIdMatch) instanceof ParseIntError) {
    return false;
  }

  if (levelMatch && safeParseInt(levelMatch) instanceof ParseIntError) {
    return false;
  }

  return true;
};
