import MemriseApi from '../MemriseApi';
import * as Schema from './schema';

export default class MeApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/me' });
  }

  public async me(): Promise<Schema.MeResponse> {
    return this.get(`/`);
  }
}

export const MeApi = new MeApiClass();
