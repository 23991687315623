import React, { ReactNode, FC } from 'react';
import { Modal } from '..';
import * as S from './styles';

export interface Props {
  errorTitle: string;
  errorDetail?: string;
  isOpen: boolean;
  testId?: string;
  children?: ReactNode;
}

const voidClose = (_event: React.MouseEvent) => {};

export const ErrorModal: FC<Props> = ({ errorTitle, errorDetail, isOpen, testId, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={voidClose}
      testId={testId}
      hideCloseButton
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <S.Error>
        <S.ErrorTitle>{errorTitle}</S.ErrorTitle>
        <S.ErrorMessage>{errorDetail}</S.ErrorMessage>
        {children}
      </S.Error>
    </Modal>
  );
};
