import React, { ReactNode, FC } from 'react';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import theme from 'src/utils/theme';
import stylisPluginRTL from 'stylis-plugin-rtl';
import { RTL_LOCALES } from 'src/utils/rtlLocales';
import GlobalStyle from './GlobalStyle';

interface Props {
  locale: string;
  children?: ReactNode;
}

/**
 * A wrapper to add themes and global style to all children
 *
 * Should appear at the top level, e.g. app, storybook
 * entry point, jest render wrapper
 */
const StyleWrapper: FC<Props> = ({ locale, children }) => {
  const rtl = RTL_LOCALES.includes(locale);
  theme.rtl = rtl;

  const themeProviderWrap = (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle theme={theme} />
        {children}
      </>
    </ThemeProvider>
  );

  if (rtl) {
    return (
      <StyleSheetManager stylisPlugins={[stylisPluginRTL]}>{themeProviderWrap}</StyleSheetManager>
    );
  } else {
    return themeProviderWrap;
  }
};

export default StyleWrapper;
