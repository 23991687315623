import { customLocaleMap } from './customLocaleMap';

export const DEFAULT_LOCALE = 'en';

export const getLocaleAndMessages = async (
  locale: string,
  fallbackLocale = DEFAULT_LOCALE,
): Promise<{
  locale: string;
  messages: Record<string, string>;
}> => {
  const lowercaseLocale = locale.toLowerCase();
  const mappedLocale = customLocaleMap[lowercaseLocale] || lowercaseLocale;

  try {
    const messages = await import(`locales/${mappedLocale}.json`);
    return {
      locale: mappedLocale,
      messages: messages.default,
    };
  } catch (e) {
    // Continue
  }

  const trimmedLocale = lowercaseLocale.split('-')[0];
  const trimmedMappedLocale = customLocaleMap[trimmedLocale] || trimmedLocale;

  try {
    const messages = await import(`locales/${trimmedMappedLocale}.json`);
    return {
      locale: trimmedMappedLocale,
      messages: messages.default,
    };
  } catch (e) {
    // Continue
  }

  const messages = await import(`locales/${fallbackLocale}.json`);
  return {
    locale: fallbackLocale,
    messages: messages.default,
  };
};
