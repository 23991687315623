import { IncomingMessage } from 'http';

export const getIp = (req: IncomingMessage): string | string[] | undefined => {
  if (req.headers) {
    // The header get information in "<ip>:<port>" format, but we only care about the IP address
    const header = req.headers['cloudfront-viewer-address'];
    if (typeof header === 'string') {
      const splitted = header.split(':');
      splitted.pop();
      return splitted.join(':');
    }
  }
  /* istanbul ignore next: untested branch of code, please test */
  if (req.connection) {
    return req.connection.remoteAddress;
  }
};
