import MemriseApi from '../MemriseApi';
import { IPLocationResponse } from './schema';

export default class IPLocationApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/ip_location' });
  }

  public async ipLocation(): Promise<IPLocationResponse> {
    return this.get(`/`);
  }
}

export const IPLocationApi = new IPLocationApiClass();
