import { SessionSourceType } from 'src/api/learningSessions/types';
import { NextSession, UserGoal } from '../shared';

export type LearningSessionsResponse = {
  learnables: Learnable[];
  progress: LearnableProgress[];
  session_source_info: SessionSourceInfo;
  settings: LearningSettings;
};

export type LearningSessionParams = {
  session_source_id?: number;
  session_source_sub_index?: number;
  session_source_type?: string;
};

export type Learnable = {
  id: number;
  learning_element: string;
  definition_element: string;
  learning_element_tokens: string[];
  definition_element_tokens: string[];
  difficulty: Difficulty;
  item_type: ItemType | null; // not present for content grammar items
  screens: { [k: string]: Screen };
  screen_config: ScreenConfig;
};

export interface LearningSettings {
  disable_multimedia: boolean;
  disable_tapping: boolean;
  prioritize_typing: boolean;
}

export type Difficulty = 'hard' | 'moderate' | 'easy' | 'unknown';

type ItemType =
  | 'word'
  | 'char'
  | 'phrase'
  | 'alphabet'
  | 'romanization'
  | 'sentence'
  | 'affix'
  | 'context';

export type Screen = Test | Presentation | SpotPattern | GrammarExamples | GrammarTip;

export type Test = {
  answer: LearnableValue;
  correct: (string[] | string)[];
  choices: string[];
  audio: LearnableValue | null;
  markdown: boolean;
  attributes: LearnableAttribute[];
  post_answer_info?: LearnableValue;
  placeholder: string[] | null;
  feedback_screen: Presentation | null;
  is_strict: boolean;
  translation_prompt: LearnableValue | null;
  template: TemplateName;
  prompt: Prompt;
  gap_prompt: null;
};

export interface LearnableValue {
  label: string;
  kind: LearnableValueKind;
  value: string | string[] | LearnableAudioValue[];
  alternatives: string[];
  style: string[];
  direction: 'source' | 'target';
  markdown: boolean;
}

export enum LearnableValueKind {
  VIDEO = 'video',
  AUDIO = 'audio',
  TEXT = 'text',
  IMAGE = 'image',
}

export type LearnableAudioValue = {
  normal: string;
  slow: string | null;
};

export enum TemplateName {
  PRESENTATION = 'presentation',
  MULTIPLE_CHOICE = 'multiple_choice',
  REVERSED_MULTIPLE_CHOICE = 'reversed_multiple_choice',
  AUDIO_MULTIPLE_CHOICE = 'audio_multiple_choice',
  TAPPING = 'tapping',
  TYPING = 'typing',
}

export type Prompt = {
  text: LearnableValue | null;
  audio: LearnableValue | null;
  video: LearnableValue | null;
  image: LearnableValue | null;
};

export type LearnableAttribute = {
  label: string;
  value: string;
};

export type Presentation = {
  item: LearnableValue;
  definition: LearnableValue;
  visible_info: LearnableValue[];
  hidden_info: LearnableValue[];
  attributes: LearnableAttribute[];
  audio: LearnableValue | null;
  markdown: boolean;
  video: LearnableValue | null;
  template: TemplateName.PRESENTATION;
};

// Not used by Web client - Grammar
export type SpotPattern = {
  template: TemplateName;
  orientation: string;
  patterns: Pattern[];
};

export type Pattern = {
  from_example: LearnableExample;
  to_example: LearnableExample;
};

export type LearnableExample = {
  item: LearnableValue;
  definition: LearnableValue;
};

export type GrammarExamples = {
  template: TemplateName;
  examples: LearnableExample[];
};

export type GrammarTip = {
  template: TemplateName;
  value: string;
  examples: LearnableExample[];
};

export type ScreenConfig = {
  explore: ScreenSettings[];
  growth_level_0: ScreenSettings[];
  growth_level_1: ScreenSettings[];
  growth_level_2: ScreenSettings[];
  growth_level_3: ScreenSettings[];
  growth_level_4: ScreenSettings[];
  growth_level_5: ScreenSettings[];
};

type ScreenSettings = {
  screen_id: string;
};

export type LearnableProgress = {
  learnable_id: number;
  correct: number; // Integer
  attempts: number; // Integer
  total_streak: number; // Integer
  current_streak: number; // Integer
  growth_level: number; // Integer
  created_date: string;
  next_date: string | null;
  last_date: string | null;
  interval: number | null;
  user_id: string;
  mem_id: string | null; // BigInt of ID, not in MemLearning
  is_difficult: boolean; // Not in MemLearning yet
  not_difficult: boolean;
  starred: boolean; // Not in MemLearning yet
  ignored: boolean; // Not in MemLearning yet
};

export type SessionSourceInfo = {
  num_due_for_review: number;
  level_id: number;
  level_name: string;
  name: string;
  learnable_ids_to_course_ids: Record<string, number | undefined>;
  template_id?: number;
  source_type: SessionSourceType;
  source_id: number;
  parent_source_id: number | null;
  parent_template_id: number | null;
  language_pair_id: number | null;
};

export type LearningSessionsEndResponse = {
  summary_stats: SummaryStats;
  next_session: NextSession;
};

export type LearningSessionsEndParams = {
  session_points: number;
  session_type: string;
  session_source_type: SessionSourceType;
  session_source_id: number;
  session_source_sub_index?: number;
};

export type UserPath = {
  name: string;
  num_learnables_learned: number;
};

export type UserScenario = {
  user_scenario_id: number;
  name: string;
  num_learnables: number;
  percent_complete: number;
  num_learnables_learned: number;
  icon_url: string;
  is_premium: boolean;
};

type SummaryStats = {
  course: UserProgressStats | null;
  rank: UserRankStats;
  level: UserProgressStats | null;
  goal: UserGoal | null;
  user_path: UserPath | null;
  user_scenario: UserScenario | null;
};

export type UserProgressStats = {
  id: number;
  name: string;
  size: number;
  due_review: number;
  learned: number;
  difficult: number;
  ignored: number;
  percent_complete: number;
  completed_this_session: boolean;
};

export type UserRankStats = {
  unlocked_new_rank: boolean;
  level_number: number;
};
