// @ts-nocheck
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';
import isPlainObject from 'lodash.isplainobject';

// Recursively converts simple object keys to camel case
export const toCamelCaseDeep = value => {
  if (isPlainObject(value)) {
    return Object.entries(value).reduce((camelized, [key, value]) => {
      camelized[camelCase(key)] = toCamelCaseDeep(value);
      return camelized;
    }, {});
  }
  if (value instanceof Array) {
    return value.map(toCamelCaseDeep);
  }
  return value;
};

// Recursively converts simple object keys to snake case
export const toSnakeCaseDeep = value => {
  if (isPlainObject(value)) {
    return Object.entries(value).reduce((snakeized, [key, value]) => {
      snakeized[snakeCase(key)] = toSnakeCaseDeep(value);
      return snakeized;
    }, {});
  }
  if (value instanceof Array) {
    return value.map(toSnakeCaseDeep);
  }
  return value;
};
