import MemriseApi from '../MemriseApi';
import { ApiStatusResponse } from './schema';

export default class ApiStatusApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/api_status' });
  }

  public async apiStatus(): Promise<ApiStatusResponse> {
    return this.get(`/`);
  }
}

export const ApiStatusApi = new ApiStatusApiClass();
