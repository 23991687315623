/* eslint-disable no-console */
import { ComponentProps } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';

export const intlErrorHandler: NonNullable<
  ComponentProps<typeof IntlProvider>['onError']
> = err => {
  if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    // Displays MISSING_TRANSLATION errors as warnings in a collapsed group
    console.groupCollapsed('Missing translation');
    console.warn(err.message);
    console.groupEnd();
    return;
  }
  throw err;
};

export const intlGetProviderLocale = (locale: string): string => {
  // Prevent use of Eastern Arabic numerals, instead use Western Arabic numerals
  // as for every other supported locale.
  if (locale === 'ar') {
    return 'ar-u-nu-latn';
  }
  return locale;
};
