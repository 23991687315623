import { SuccessResponse } from 'src/api/types';
import MemriseApi from '../MemriseApi';

/**
 * The Learnables endpoints allow clients to fetch the content taught in courses, as well as perform actions related it on behalf of users.
 * *Documentation*: https://docs.memrise.team/reference/rest-api-endpoints/learnables.html
 */
export default class LearnablesApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/learnables' });
  }

  public async ignore(learnableId: number): Promise<SuccessResponse> {
    return this.post(`/${learnableId}/ignore/`, {});
  }

  public async unignore(learnableId: number): Promise<SuccessResponse> {
    return this.delete(`/${learnableId}/ignore/`, {});
  }

  public async star(learnableId: number): Promise<SuccessResponse> {
    return this.post(`/${learnableId}/star/`, {});
  }

  public async unstar(learnableId: number): Promise<SuccessResponse> {
    return this.delete(`/${learnableId}/star/`, {});
  }
}

export const LearnablesApi = new LearnablesApiClass();
