import cookieParser from 'cookie';
// @ts-ignore: Could not find a declaration file for module 'cookie-dough'
import Cookie from 'cookie-dough';

/* istanbul ignore next: this function only exists to mock in tests anyway,
 * we can't test getting browser cookies in unit tests */
export const getCookie = (name: string): string => Cookie().get(name);

/* istanbul ignore next: this function only exists to mock in tests anyway,
 * we can't test getting browser cookies in unit tests */
export const setCookie = (name: string, value: string): string =>
  Cookie().set(name, value, { path: '/' });

type ObjectToCookies = (cookiesObj: Record<string, unknown>) => string;
export const objectToCookie: ObjectToCookies = cookiesObj =>
  Object.entries(cookiesObj)
    .map(v => v.join('='))
    .join('; ');

/**
 * Get cookies client side or server side
 */
export const getCookies = (req?: { cookies?: Record<string, string> }): Record<string, string> =>
  req && req.cookies
    ? req.cookies
    : /* istanbul ignore next: untested branch of code, please test */ cookieParser.parse(
        document.cookie,
      );

/**
 * Returns true if the browser has cookies enabled, false if not
 * Defaults to true for SSR
 */
export const checkCookiesAreEnabled = (): boolean =>
  typeof window === 'undefined' || window.navigator.cookieEnabled;

export function useCookiesAreEnabled(): boolean {
  return checkCookiesAreEnabled();
}
