import { DefaultTheme } from 'styled-components';
import { PickValues } from 'src/types/utility';
import typography from './typography';

const baseZIndices = {
  z1: 100 as const,
  z2: 200 as const,
  z3: 300 as const,
  z4: 400 as const,
  z5: 500 as const,
  z6: 600 as const,
  z7: 700 as const,
  z8: 800 as const,
  z9: 900 as const,
  z10: 1000 as const,
};

// typecasting with `as` lets you get literals on autocomplete popup
//
// This theme gets used everywhere and full names for the keys
// can be a little verbose and messy in style-components so
// they are shortened to a single letter here

const theme = {
  /* ==========================================================
       Colors
    ========================================================== */
  co: Object.freeze({
    primary0: '#CC9600' as const,
    primary10: '#E9AC05' as const,
    primary20: '#FFBB00' as const,
    primary30: '#FFC933' as const,
    primary40: '#FFCA58' as const,
    primary50: '#FFD666' as const,
    primary60: '#FFDD80' as const,
    primary70: '#FFE8A8' as const,
    primary80: '#FCEBBD' as const,
    primary90: '#FFF1CC' as const,
    primary95: '#FFF5D7' as const,
    primary98: '#FFF8E5' as const,
    primary99: '#FFFCF3' as const,

    secondary0: '#001122' as const,
    secondary10: '#212C3B' as const,
    secondary20: '#293749' as const,
    secondary30: '#344559' as const,
    secondary40: '#46566B' as const,
    secondary50: '#536175' as const,
    secondary60: '#8E94A0' as const,
    secondary70: '#A8AEBA' as const,
    secondary80: '#BCC2CF' as const,
    secondary90: '#CCD2DE' as const,
    secondary95: '#E7EAEF' as const,
    secondary98: '#EFF1F3' as const,
    secondary99: '#F6F6F6' as const,

    tertiary0: '#261A52' as const,
    tertiary10: '#4D14AC' as const,
    tertiary20: '#5D29B5' as const,
    tertiary30: '#6E3DBF' as const,
    tertiary40: '#7E52C8' as const,
    tertiary50: '#9E7ADA' as const,
    tertiary60: '#BFA3ED' as const,
    tertiary70: '#DFCCFF' as const,
    tertiary80: '#E5D6FF' as const,
    tertiary90: '#ECE0FF' as const,
    tertiary95: '#F2EBFF' as const,
    tertiary98: '#F5F0FF' as const,
    tertiary99: '#FAFAFD' as const,

    information0: '#1F3862' as const,
    information10: '#254172' as const,
    information20: '#2D5190' as const,
    information30: '#127097' as const,
    information40: '#1580AD' as const,
    information50: '#009FBB' as const,
    information60: '#2BADC8' as const,
    information70: '#8BD0DE' as const,
    information80: '#95D8E6' as const,
    information90: '#B7EBF7' as const,
    information95: '#D0F7FF' as const,
    information98: '#E9FBFF' as const,
    information99: '#F3FDFF' as const,

    success0: '#014135' as const,
    success10: '#025545' as const,
    success20: '#076B51' as const,
    success30: '#007456' as const,
    success40: '#00A778' as const,
    success50: '#04BC88' as const,
    success60: '#04CB93' as const,
    success70: '#00DB9D' as const,
    success80: '#5DE7C0' as const,
    success90: '#B2F8E4' as const,
    success95: '#D6FFF3' as const,
    success98: '#E3FFF7' as const,
    success99: '#EDFAF5' as const,

    error0: '#1F0503' as const,
    error10: '#410E0B' as const,
    error20: '#6D0009' as const,
    error30: '#8C1D18' as const,
    error40: '#B13039' as const,
    error50: '#DC362E' as const,
    error60: '#E46962' as const,
    error70: '#F28782' as const,
    error80: '#FFBFBC' as const,
    error90: '#FFDDDB' as const,
    error95: '#FDEDED' as const,
    error98: '#FFF5F5' as const,
    error99: '#FFF9F9' as const,

    warning0: '#8D4021' as const,
    warning10: '#C35C32' as const,
    warning20: '#ED6B35' as const,
    warning30: '#FC743D' as const,
    warning40: '#FE7F4B' as const,
    warning50: '#FF976A' as const,
    warning60: '#FFAC88' as const,
    warning70: '#FFCBB4' as const,
    warning80: '#FFD5C3' as const,
    warning90: '#FFE0D2' as const,
    warning95: '#FFE9DF' as const,
    warning98: '#FFF5F1' as const,
    warning99: '#FFFBFA' as const,

    neutral0: '#000000' as const,
    neutral10: '#1C1B1F' as const,
    neutral20: '#313033' as const,
    neutral30: '#484649' as const,
    neutral40: '#605D62' as const,
    neutral50: '#787579' as const,
    neutral60: '#939094' as const,
    neutral70: '#AFAFB0' as const,
    neutral80: '#CCD2DE' as const,
    neutral90: '#D9DEE8' as const,
    neutral95: '#E7EAF0' as const,
    neutral98: '#F6F6F6' as const,
    neutral99: '#FFFFFF' as const,

    facebookBlue: '#4267B2' as const,
  }),

  /* ==========================================================
      Sizing
    ========================================================== */

  sz: Object.freeze({
    /* Small sizing ---------------------------------------- */

    s0: '0' as const,

    /**
     * Formely known as `--extraExtraSmall`
     *
     * 2px (typical)
     */
    s05: '0.125rem' as const,

    /** 4px (typical) */
    s1: '0.25rem' as const,

    /** 8px (typical) */
    s2: '0.5rem' as const,

    /** 12px (typical) */
    s3: '0.75rem' as const,

    /** 16px (typical) */
    s4: '1rem' as const,

    /** 20px (typical) */
    s5: '1.25rem' as const,

    /** 24px (typical) */
    s6: '1.5rem' as const,

    /** 28px (typical) */
    s7: '1.75rem' as const,

    /** 32px (typical) */
    s8: '2rem' as const,

    /** 40px (typical) */
    s10: '2.5rem' as const,

    /** 44px (typical) */
    s11: '2.75rem' as const,

    /** 48px (typical) */
    s12: '3rem' as const,

    /** 56px (typical) */
    s14: '3.5rem' as const,

    /** 64px (typical) */
    s16: '4rem' as const,

    /** 72px (typical) */
    s18: '4.5rem' as const,

    /** 80px (typical) */
    s20: '5rem' as const,

    /** 96px (typical) */
    s24: '6rem' as const,

    /** 104px (typical) */
    s26: '6.5rem' as const,

    /** 112px (typical) */
    s28: '7rem' as const,

    /** 128px (typical) */
    s32: '8rem' as const,

    /** 160px (typical) */
    s40: '10rem' as const,

    /** 176px (typical) */
    s44: '11rem' as const,

    /** 192px (typical) */
    s48: '12rem' as const,

    /** 236px (typical) */
    s54: '14.75rem' as const,

    /** 256px (typical) */
    s64: '16rem' as const,

    /** 272px (typical) */
    s68: '17rem' as const,

    /* Big sizing ------------------------------------------ */

    /** 320px (typical) */
    xs: '20rem' as const,

    /** 400px (typical) */
    s: '25rem' as const,

    /** 432px (typical) */
    m: '27rem' as const,

    /** 480px (typical) */
    sm: '30rem' as const,

    /** 560px (typical) */
    ms: '35rem' as const,

    /** 640px (typical) */
    md: '40rem' as const,

    /**
     * Formerly known as var(--viewWidth)
     *
     * 720px (typical)
     */
    ml: '45rem' as const,

    /** 800px (typical) */
    lg: '50rem' as const,

    /** 960px (typical) */
    xl: '60rem' as const,

    /** 1280px (typical) */
    xxl: '80rem' as const,

    /** 1600px (typical) */
    xxxl: '100rem' as const,

    /** 1920px (typical) */
    xxxxl: '120rem' as const,

    /** specific breakpoints */
    /** 1024px (typical) */
    maxMobileBreakpoint: '64rem' as const, // use for @media(max-width...)
    minMobileBreakpoint: '64.0625rem' as const, // use for @media(min-width...)
  }),

  /* ==========================================================
      Typography - deprecated (please use new `typography` below)
    ========================================================== */

  DEPRECATED_ty: Object.freeze({
    /* Sizes ----------------------------------------------- */

    /** 10px */
    smallest: '0.625rem' as const,
    /** 12px */
    smaller: '0.75rem' as const,
    /** 14px */
    small: '0.875rem' as const,
    /** 16px */
    medium: '1rem' as const,
    /** 18px */
    mediumLarge: '1.125rem' as const,
    /** 20px */
    large: '1.25rem' as const,
    /** 24px */
    larger: '1.5rem' as const,
    /** 30px */
    largerStill: '1.875rem' as const,
    /** 36px */
    largest: '2.25rem' as const,
    /** 48px */
    displaySmall: '3rem' as const,
    /** 60px */
    display: '3.75rem' as const,
    displayLarge: '' as const,

    /* Stacks ---------------------------------------------- */

    boing: '"Boing","Noto", "Noto Sans JP", "Open Sans",Sans-Serif',
    openSans: '"Open Sans",Sans-Serif',
    satisfy: '"Satisfy",Sans-Serif',
    sfCompactDisplay: '"SF Compact Display"',

    /* Weights --------------------------------------------- */

    mediumWeight: '500' as const,
    semiBold: '600' as const,
    bold: '700' as const,
  }),

  typography,

  /* ==========================================================
      Borders
    ========================================================== */

  bo: Object.freeze({
    // Thickness
    thinWidth: '1px' as const,
    mediumWidth: '2px' as const,
    thickWidth: '4px' as const,
    veryThickWidth: '6px' as const,

    // Radius
    extraSmall: '0.25rem' as const,
    small: '0.5rem' as const,
    mediumSmall: '0.75rem' as const,
    medium: '1rem' as const,
    /** 20px */
    mediumLarger: '1.25rem' as const,
    mediumLarge: '1.5rem' as const,
    large: '2rem' as const,
    elliptical: '50%' as const,
    round: '9999rem' as const,
    memrise: '56% 48% 53% 56% / 70% 50% 58% 41%' as const,
  }),

  /* ==========================================================
      Z-Index
    ========================================================== */

  zi: Object.freeze({
    ...baseZIndices,

    // assign global, named indices below as needed

    /**
     * For headers at the top of pages
     */
    header: baseZIndices.z6,

    /**
     * For modals and dialogs
     */
    modal: baseZIndices.z9,

    /**
     * For when you really need to show something on top.
     * Should not be used in production
     */
    debug: baseZIndices.z10,
  }),

  /* ==========================================================
      Shadows
    ========================================================== */

  sh: Object.freeze({
    s1: '0 1px 3px hsla(0, 0%, 0%, 0.14), 0 1px 2px hsla(0, 0%, 0%, 0.24)' as const,
    s2: '0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12)' as const,
    s3: '0 10px 20px hsla(0, 0%, 0%, 0.15), 0 3px 6px hsla(0, 0%, 0%, 0.1)' as const,
    s4: '0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.2)' as const,
    s5: '0 15px 35px hsla(0, 0%, 0%, 0.2)' as const,
    s6: '0 -2.25px 4px hsla(0, 0%, 0%, 0.25)' as const,
  }),

  /* ==========================================================
    Right to Left text
  ========================================================== */
  rtl: false,
};

export default theme;

export type Space = keyof typeof theme.sz;

export type Breakpoint = PickValues<
  Space,
  'xs' | 's' | 'm' | 'sm' | 'ms' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl'
>;

export type Color = keyof typeof theme.co;

export type Theme = typeof theme;

/* istanbul ignore next: manually tested aesthetically */
export const rtlFlip = (themeParam: DefaultTheme) => {
  return themeParam.rtl ? '-1' : '1';
};
