import { DefaultTheme, css } from 'styled-components';

export const addCustomScrollbar = (theme: DefaultTheme) => css`
  // Firefox scrollbar
  scrollbar-width: thin;

  // Webkit scrollbar
  &::-webkit-scrollbar {
    width: ${theme.sz.s1};
    height: ${theme.sz.s1};
  }
  &::-webkit-scrollbar-track-piece {
    margin: ${theme.sz.s3};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${theme.bo.round};
    background: ${theme.co.secondary80};
  }
`;
