export const ROUTES = {
  aprender: {
    difficult: '/aprender/difficult',
    learn: '/aprender/learn',
    review: '/aprender/review',
    speed: '/aprender/speed',
  },
  bienvenue: '/bienvenue',
  dashboard: {
    old: '/dashboard',
  },
  settings: {
    learning: '/learning-settings',
    user: '/settings/',
  },
  signIn: '/signin',
  signOut: '/signout',
} as const;
