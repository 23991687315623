import {
  ModeSelectorSessionOption as SharedModeSelectorSessionOption,
  NextSession as SharedNextSession,
  ProSessionType as SharedProSessionType,
  SessionType as SharedSessionType,
  StandardSessionType as SharedStandardSessionType,
  SuggestableSessionType as SharedSuggestableSessionType,
  UnlockedState as SharedUnlockedState,
  WebSessionType as SharedWebSessionType,
} from 'src/api/shared/types';
import { CardData } from '../../apps/Shared/LearningSession/Common/learnableCards/utils';

export enum TemplateName {
  PRESENTATION = 'presentation',
  MULTIPLE_CHOICE = 'multiple_choice',
  REVERSED_MULTIPLE_CHOICE = 'reversed_multiple_choice',
  AUDIO_MULTIPLE_CHOICE = 'audio_multiple_choice',
  TAPPING = 'tapping',
  TYPING = 'typing',
}

export enum LearnableValueKind {
  VIDEO = 'video',
  AUDIO = 'audio',
  TEXT = 'text',
  IMAGE = 'image',
}

export interface LearnableAudioValue {
  normal: string;
  slow: string | null;
}

export interface LearnableValue {
  label: string;
  kind: LearnableValueKind;
  value: string | string[] | LearnableAudioValue[];
  alternatives: string[];
  style: string[];
  direction: 'source' | 'target';
  markdown: boolean;
}

export interface Prompt {
  text: LearnableValue | null;
  audio: LearnableValue | null;
  video: LearnableValue | null;
  image: LearnableValue | null;
}

export interface LearnableAttribute {
  label: string;
  value: string;
}

export interface Presentation {
  item: LearnableValue;
  definition: LearnableValue;
  visibleInfo: LearnableValue[];
  hiddenInfo: LearnableValue[];
  attributes: LearnableAttribute[];
  audio: LearnableValue | null;
  markdown: boolean;
  video: LearnableValue | null;
  template: TemplateName.PRESENTATION;
}

export type Screen = Test | Presentation;

export interface ScreenSettings {
  screenId: string;
}

export interface ScreenConfig {
  explore: ScreenSettings[];
  growthLevel0: ScreenSettings[];
  growthLevel1: ScreenSettings[];
  growthLevel2: ScreenSettings[];
  growthLevel3: ScreenSettings[];
  growthLevel4: ScreenSettings[];
  growthLevel5: ScreenSettings[];
}

// We could also, in theory, get grammar tests, but we ignore them for web_client
export interface Test {
  answer: LearnableValue;
  correct: (string[] | string)[];
  choices: string[];
  audio: LearnableValue | null;
  markdown: boolean;
  attributes: LearnableAttribute[];
  postAnswerInfo?: LearnableValue;
  placeholder: string[] | null;
  feedbackScreen: Presentation | null;
  isStrict: boolean;
  translationPrompt: LearnableValue | null;
  template: Exclude<TemplateName, TemplateName.PRESENTATION>;
  prompt: Prompt;
  gapPrompt: null;
}

export type Difficulty = 'hard' | 'moderate' | 'easy' | 'unknown';

export type ItemType =
  | 'word'
  | 'char'
  | 'phrase'
  | 'alphabet'
  | 'romanization'
  | 'sentence'
  | 'affix'
  | 'context';

export type Learnable = {
  id: number;
  learningElement: string;
  definitionElement: string;
  learningElementTokens: string[];
  definitionElementTokens: string[];
  difficulty: Difficulty;
  itemType: ItemType | null; // not present for content grammar items
  screens: Screen[];
  screenConfig: ScreenConfig;
};

export interface CompactLearnableProgress {
  learnableId: number;
  correct: number; // Integer
  attempts: number; // Integer
  totalStreak: number; // Integer
  currentStreak: number; // Integer
  growthLevel: number; // Integer
  createdDate: string;
  nextDate: string | null;
  lastDate: string | null;
  interval: number | null;
  notDifficult: boolean;
  starred: boolean;
  ignored: boolean;
}

export interface CompactLearnableWithProgress {
  learnable: Learnable;
  progress: CompactLearnableProgress;
}

export type LearnableId = number;

export type LearnableProgressExtras = {
  userId: string;
  memId: string | null; // BigInt of ID, not in MemLearning
  isDifficult: boolean; // whether the answer will be used in difficult words and should be displayed as difficult
  notDifficult: boolean; // marked as not difficult by the user
  starred: boolean; // marked as difficult by the user
  ignored: boolean;
};

export type LearnableProgress = CompactLearnableProgress & LearnableProgressExtras;

// Used to present a summary of a learnable value
export interface LearnableRowInfo {
  label: string;
  value: string;
  kind: LearnableValueKind;
  alternatives?: string[];
  showBigger?: boolean;
}

export type Correctness = 'Incorrect' | 'NearlyCorrect' | 'Correct';
export type UnlockedState = SharedUnlockedState;

export interface TestAnswer {
  answer: string;
  correctness: Correctness;
  answeredDateTime: Date;
}

export type TestPromptType = 'Any' | 'Text' | 'Audio' | 'Video' | 'Image' | null; // null is an option for grammar modes

/** Watch out! This is uncannily similar to TemplateName */
export type ResponseModel =
  | 'MultipleChoice'
  | 'ReverseMultipleChoice'
  | 'AudioMultipleChoice'
  | 'Tapping'
  | 'Typing'
  | 'Pronunciation';

export type Direction = 'target' | 'source';

export interface TestType {
  model: ResponseModel;
  prompt: TestPromptType;
  difficulty: Difficulty | null;
  isCopy: boolean;
  isFlipped: boolean;
  weight?: number | null;
  hasMultimedia: boolean;
}

export interface TestResult {
  learnableProgress: CompactLearnableProgress;
  points: number;
  correctness: Correctness;
  totalSessionPoints: number;
  isDifficult: boolean;
}

export interface MultipleChoiceTestCard {
  kind: 'TestCard';
  onTestAnswer: (answer: string) => TestResult;
  onTestSkipped: () => void;
  onContinue: () => void;
  learnableId: string;
  testType: TestType;
  choices: string[];
  answer: string;
  responseType: 'Text' | 'Audio' | 'Image';
}

export interface AudioMultipleChoiceTestCard {
  kind: 'TestCard';
  onTestAnswer: (answer: string) => TestResult;
  onTestSkipped: () => void;
  onContinue: () => void;
  learnableId: string;
  testType: TestType;
  answer: { stringValue: string };
  choices: { stringValue: string }[];
}

export interface TypingTestCard extends MultipleChoiceTestCard {
  answers: string[];
  isCopy: boolean;
}

export interface TappingTestCard extends MultipleChoiceTestCard {
  /**
   * The backend/memlearning return `string[][]` but we do not
   * need this type for the React component yet as we have not
   * yet implemented auto-complete.
   */
  answers: string[];
  choices: string[];
  isCopy: boolean;
}

export type TestCard =
  | MultipleChoiceTestCard
  | TypingTestCard
  | AudioMultipleChoiceTestCard
  | TappingTestCard;

export type PresentationCard = {
  kind: 'PresentationCard';
  onContinue: () => void;
  learnableId: string;
  previousIncorrectAnswer: string | null;
};
export type Card = PresentationCard | TestCard;

export type CardState = {
  kind: 'CardState';
  card: Card;
  sessionPercentComplete: number;
  growthLevel: number;
  data: CardData;
};

export type LoadingState = {
  kind: 'LoadingState';
};
export type SessionState = LoadingState | CardState;

export interface LearnableWithProgress {
  progress: LearnableProgress | null;
  learnable: Learnable;
}

export { SharedSessionType as SessionType };
export type WebSessionType = SharedWebSessionType;
export type SuggestableSessionType = SharedSuggestableSessionType;
export type ProSessionType = SharedProSessionType;
export type StandardSessionType = SharedStandardSessionType;

export interface LearningEvent {
  boxTemplate: string;
  bonusPoints: number;
  courseId?: number;
  languagePairId?: number;
  scenarioId?: number;
  points: number;
  /** Float */
  score: number;
  /** How much time the user spent seeing the box in milliseconds */
  timeSpent: number;
  /** Unix timestamp in seconds UTC */
  when: number;
  /** Optional string containing the users answer */
  givenAnswer: string;
  /** e.g. 'Hola' for Spanish 1 from en source language */
  learningElement: string;
  /** e.g. 'Hello' for Spanish 1 from en source language */
  definitionElement: string;
  testId: string;
  memId: string | null;

  attempts: number;
  /** total correct answers in a row */
  correct: number;
  createdDate: number;
  /** total_streak if total_streak > 0 */
  currentStreak: number;
  growthLevel: number;
  /** If true, prevents item appearing in learning session */
  ignored: boolean;
  /** Float */
  interval: number | null;
  learnableId: number;
  /** The unix datetime this learnable is due to be reviewed. Past this date it'd be considered due to review */
  nextDate: number | null;
  lastDate: number;
  /** Whether the user explicitly marked the item as not difficult */
  notDifficult: boolean;
  /** Whether the user explicitly marked the item as difficult */
  starred: boolean;
  /** can be negative, +3 means the users got the answer right 3 times in a row, -3 would mean the user got the answer wrong 3 times in a row */
  totalStreak: number;
}

export interface SummaryItem {
  item: LearnableRowInfo;
  definition: LearnableRowInfo;
  growthLevel: number;
  learnableId: number;
  dueForReview?: boolean;
}

export type ModeSelectorSessionOption = SharedModeSelectorSessionOption;
export type NextSession = SharedNextSession;

export interface UserGoalStats {
  /**
   * How many points the user needs to hit their Daily Goal
   */
  pointsTarget: number;

  /**
   * How many points the user has towards their Daily Goal
   */
  pointsTowardsGoal: number;
}

export interface UserRankProgression {
  /**
   * Which rank the user is on
   */
  levelNumber: number;

  /** Whether thew user ranked up this session */
  unlockedNewRank: boolean;
}

export interface UserProgressStats {
  id: number;
  name: string;
  learned: number;
  percentComplete: number;
  completedThisSession: boolean;
  size: number;
  ignored: number;
}

export interface UserPath {
  name: string;
  totalItemsLearned: number;
}

export interface UserScenario {
  userScenarioId: number;
  name: string;
  numThings: number;
  percentComplete: number;
  itemsLearned: number;
  iconUrl: string;
  isPremium: boolean;
}

export interface SummaryStats {
  goal: UserGoalStats | null;
  rank: UserRankProgression;
  course: UserProgressStats | null;
  level: UserProgressStats | null;
  userPath: UserPath | null;
  userScenario: UserScenario | null;
}

export interface SessionSourceInfo {
  numDueForReview: number;
  levelId: number | null;
  levelName: string | null;
  name: string;
  learnableIdsToCourseIds: Record<string, number | undefined>;
  templateId?: number;
  sourceType: SessionSourceType;
  sourceId: number;
  parentSourceId?: number;
  parentTemplateId?: number;
  languagePairId: number | null;
}

export interface ProgressPreferences {
  learnableId: number;
  difficult: boolean; // used to display the toggle
  ignored: boolean; // used to display the toggle & sent with learning events
  notDifficult: boolean; // Sent with LearningEvents
  starred: boolean; // Sent with LearningEvents
}

// See mobile implementations here:
// https://github.com/Memrise/Memrise-iOS/blob/e32a9131f3b73f71d355b973a716f544c4008f1c/LocalPods/MemriseDomain/Source/UseCases/DailyGoalUseCase.swift#L4
// https://github.com/Memrise/Memrise-Android/blob/aae8076774beb10f9db6137729a0c963f02d496b/legacy-core/src/main/java/com/memrise/android/memrisecompanion/legacyutil/GoalOption.kt
export type GoalPoints = 1500 | 6000 | 20000;

export type Goal = {
  /** How many points the user has towards the goal */
  points: number;
  /** Number of target points */
  goal: GoalPoints;
};

export interface TestSettings {
  disableMultimedia: boolean;
  disableTapping: boolean;
  prioritizeTyping: boolean;
}

export interface LearningSessionError {
  header: { id: string };
  description: { id: string };
  linkToPlans?: boolean;
  extraInfoLink?: { url: string; message: { id: string } };
}

export type SessionSourceType =
  | 'course'
  | 'category'
  | 'course_id_and_level_index'
  | 'scenario'
  | 'language_pair';
