import { IncomingMessage } from 'http';
import { NextPageContext } from 'next';
import { AppContext } from 'next/app';

// Extend NextAppContext in order to be able to use our added cookies and locale information
//
// Original source below (permalink):
// https://github.com/zeit/next.js/blob/e5fa2beac1bed115741cad4d0f9b75d73a9eb573/packages/next-server/lib/utils.ts#L73

export interface LocaleProps {
  locale: string;
  messages: Record<string, string>;
}

export type PartialLocaleProps = Partial<LocaleProps>;

interface MemriseIncomingMessage
  extends IncomingMessage,
    // XXX
    // Partial LocaleProps needed to avoid difficulty in overwriting
    // getInitialProps where extending Next's App class means that
    // getInitialProps is expected to take NextAppContext as the type
    // of it's param
    // These _should_ always be present in reality
    PartialLocaleProps {
  cookies?: { [key: string]: string };
}

export interface MemrisePageContext extends NextPageContext {
  req?: MemriseIncomingMessage;
}

/**
 * The type of the arguments for getInitialProps in `_app.tsx`.
 * An overwrite of Next's AppContext type
 *
 * See:
 * https://github.com/zeit/next.js/blob/8c19d78a088ecd343e44d40f496f6d68ffdf05e6/packages/next/pages/_app.tsx#L14
 * https://github.com/zeit/next.js/blob/8c19d78a088ecd343e44d40f496f6d68ffdf05e6/packages/next-server/lib/utils.ts#L105
 */
export interface MemriseAppContext extends AppContext {
  ctx: MemrisePageContext;
}

export function isMemriseAppContext(
  ctx: NextPageContext | MemrisePageContext,
): ctx is MemrisePageContext {
  const checkedCtx = ctx as MemrisePageContext;
  return !!(checkedCtx.req && checkedCtx.req.cookies);
}
