import React, { forwardRef, HTMLProps, Ref } from 'react';
import * as S from './styles';
import isIconName, { BaseButtonProps } from './index';

type AnchorButtonProps = BaseButtonProps & HTMLProps<HTMLAnchorElement>;

export const AnchorButton = forwardRef(
  (
    {
      children,
      icon,
      disabled,
      href,
      wide = false,
      buttonType = 'light-blue',
      buttonSize = 'small',
      target,
      onClick,
      onKeyUp,
      a11yText,
      noWrap,
    }: AnchorButtonProps,
    ref?: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <S.A
        $buttonType={buttonType}
        $buttonSize={buttonSize}
        $disabled={disabled}
        href={disabled ? undefined : href}
        $wide={wide}
        target={target}
        rel={target && 'noopener noreferrer'}
        ref={ref}
        onClick={disabled ? undefined : onClick}
        onKeyUp={onKeyUp}
        aria-label={a11yText}
        title={a11yText}
        $noWrap={noWrap}
        data-testid="scenarios-anchor-button"
      >
        {isIconName(icon) ? (
          <S.Icon $disabled={disabled} name={icon} $buttonType={buttonType} />
        ) : (
          icon
        )}
        {children && <S.Content $isLeft={!!icon}>{children}</S.Content>}
      </S.A>
    );
  },
);

AnchorButton.displayName = 'ScenariosAnchorButton';
