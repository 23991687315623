import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const PageContent = styled.div`
  flex-grow: 1;
  overflow-x: clip;
`;
