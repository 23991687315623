import { ServerResponse } from 'http';
import { MemriseApiError } from 'src/api/errors';

/**
 * Used for general errors we throw
 * Message will be displayed to the user on the _error page
 */
export class MemriseError extends Error {
  httpStatusCode?: number;
  message: string;

  constructor(message: string, httpStatusCode?: number) {
    super(message);
    this.name = 'MemriseError';
    this.httpStatusCode = httpStatusCode;
    this.message = message;
  }
}

/**
 * This any type comes from the NextPageContext which could return a res and err object
 * We first check if the error is known (MemriseApiError or MemriseError)
 * Then we check is the res or err from NextContext had a statusCode property
 */
export const convertToMemriseError = (
  err: (Error & { statusCode?: number }) | MemriseError | MemriseApiError | null,
  res?: ServerResponse,
): MemriseError => {
  /* istanbul ignore else: empty branch */
  if (err) {
    if (err instanceof MemriseError) return err;

    if (err instanceof MemriseApiError) {
      return new MemriseError('Memrise API returned error: ' + err.code, err.httpStatusCode);
    }
  }

  // ServerResponse.statusCode or err.statusCode from NextPageContext
  const statusCode =
    res && res.statusCode
      ? res.statusCode
      : err && err.statusCode
        ? /* istanbul ignore next: untested branch of code, please test */ err.statusCode
        : undefined;

  return new MemriseError('', statusCode);
};
