import { UrlObject } from 'url';
import React, { ComponentProps, FC, KeyboardEvent, MouseEventHandler } from 'react';
import Link from 'next/link';
import { AnchorButton } from '../Button/AnchorButton';

interface LinkButtonProps extends Omit<ComponentProps<typeof AnchorButton>, 'href'> {
  href?: string | UrlObject; // path inside pages directory
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onKeyUp?: (event?: KeyboardEvent<HTMLAnchorElement>) => void;
  a11yText?: string;
}

/**
 * A wrapper component that gives you Next.js-compatible
 * internal links with Memrise button styles
 *
 * https://nextjs.org/docs#dynamic-routes
 *
 * Ref passed is to avoid issue listed here:
 * https://github.com/zeit/next.js/issues/7915
 */
export const LinkButton: FC<LinkButtonProps> = ({
  href,
  onClick,
  onKeyUp,
  a11yText,
  ...restProps
}) => {
  const child = (
    <AnchorButton onClick={onClick} onKeyUp={onKeyUp} a11yText={a11yText} {...restProps} />
  );

  return href != null ? (
    <Link href={href} passHref legacyBehavior>
      {child}
    </Link>
  ) : (
    child
  );
};
