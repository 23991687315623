import React, { ComponentType, FC } from 'react';
import * as S from './styles';
import { IconName } from '.';

type IconSet = { [key in IconName]: ComponentType };

export interface IconProps {
  name: IconName;
  className?: string;
  /** If true, replaces the icon fill with currentcolor, so the color can be changed as if it were text */
  useCurrentColor?: boolean;
  /** Helps with icon alignment in some cases (e.g. when the icon is placed independently). */
  altAlignment?: boolean;
  children?: never;
}

export const SvgHelperWrapper = ({
  Svg,
  ...rest
}: {
  Svg: ComponentType & { _defaultProps?: Record<string, unknown> };
}) => {
  if (Svg.defaultProps) {
    Svg._defaultProps = Svg.defaultProps;
    Svg.defaultProps = undefined;
  }
  return <Svg {...Svg._defaultProps} {...rest} />;
};

type IconHelper = (iconSet: IconSet) => FC<IconProps>;
const iconHelper: IconHelper = iconSet =>
  function Icon({ name, useCurrentColor, altAlignment, className }: IconProps) {
    const IconSVG: React.ComponentType = iconSet[name];
    return (
      <S.Root
        data-testid={name}
        className={className}
        $useCurrentColor={useCurrentColor}
        $altAlignment={altAlignment}
      >
        <SvgHelperWrapper Svg={IconSVG} />
      </S.Root>
    );
  };

export default iconHelper;
