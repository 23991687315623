import MemriseApi from '../MemriseApi';

class DevToolsApiClass extends MemriseApi {
  constructor() {
    super({ endpointPrefix: '/dev_tools' });
  }

  /**
   * Toggles a dev user's subscription between free and premium
   */
  public async toggleUserSubscription() {
    return this.post('/user_subscription/', {});
  }

  /**
   * Auto (un)learns a number of items in a user's course
   */
  public async learnCourseItems({
    courseId,
    numItems,
  }: {
    courseId: number;
    numItems: number | null;
  }) {
    return this.post('/learn_course_items/', {
      bodyObject: {
        courseId,
        numItems,
      },
    });
  }

  /**
   * Restart a course
   */
  public async restartCourse({ courseId }: { courseId: number }) {
    return this.post('/restart_course/', {
      bodyObject: {
        courseId,
      },
    });
  }

  /**
   * Change current language of user interface
   */
  public async changeLanguage({ language }: { language: string }) {
    return this.post('/set_ui_language/', {
      bodyObject: {
        language,
      },
    });
  }

  /**
   * Add course items to ready for review
   */
  public async addReviewItems({
    courseId,
    numItems,
  }: {
    courseId: number;
    numItems: number | null;
  }) {
    return this.post('/add_review_items/', {
      bodyObject: {
        courseId,
        numItems,
      },
    });
  }
}

export const DevToolsApi = new DevToolsApiClass();
