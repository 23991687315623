import { COOKIES_KEYS } from 'src/constants/cookies.const';
import { stringify } from 'src/utils/stringifyUrl';
import { memriseApiFetch } from './memriseApiFetch';
import { HttpErrorStatusCodes, MemriseApiError } from './errors';

/**
 * The JSON response from the server which gives details
 * about the error which occurred
 */
interface ErrorData {
  error?: string;
  code: string;
}

// This check isn't ideal as this would interpret any returned
// data with a 'code' property as an error. However, this
// is a useful proxy which helps us add the correct return
// type on the JSON response.
// See webapp/memrise/rest_api/web/endpoints/payments.py
// `_error_response` for an example of how this code is set
export function isErrorData<Unsure>(unsure: Unsure | ErrorData): unsure is ErrorData {
  if (!unsure) return false;
  const { code, error } = unsure as ErrorData;
  return code !== undefined || error !== undefined;
}

export function codeFromNumber(statusCodeNumber: number): string {
  return statusCodeNumber in HttpErrorStatusCodes
    ? HttpErrorStatusCodes[statusCodeNumber]
    : statusCodeNumber.toString();
}

export function throwApiError(response: Response, fullEndpoint: string): Promise<never> {
  const { status, url } = response;
  return response
    .json()
    .then(res => {
      // If the server puts useful info on the error, throw with that info.
      // Otherwise, throw with what info we have.
      const { error: errorMessage, code } = isErrorData(res)
        ? res
        : { error: undefined, code: undefined };
      throw new MemriseApiError(
        status,
        code || codeFromNumber(status),
        errorMessage ||
          `${status} (${codeFromNumber(status)}) error calling web API ${fullEndpoint}`,
        url,
      );
    })
    .catch(error => {
      if (status === 502 || status === 504) {
        // Gateway errors are handled differently on different browsers and this will enable us to group them better.
        throw new MemriseApiError(
          status,
          codeFromNumber(status),
          status === 502 ? 'Bad Gateway.' : 'Gateway Timeout.',
          url,
        );
      }
      // If we can't parse the response as JSON (e.g. it is HTML) then throw
      throw error instanceof MemriseApiError
        ? error
        : new MemriseApiError(status, codeFromNumber(status), error.message, url);
    });
}

/**
 * Checks for errors in the response and ensures the json
 * is not an error response. Returns the checked data
 */
export async function parseResponse<Data extends Record<string, unknown>>(
  response: Response,
  fullEndpoint: string,
): Promise<Data> {
  await response;

  try {
    if (response.ok) {
      return response.json();
    } else {
      // This is a failsafe in case the response has no body
      throw new Error(`Response not ok: ${JSON.stringify(response)}. Endpoint: ${fullEndpoint}`);
    }
  } catch (e) {
    return throwApiError(response, fullEndpoint);
  }
}

interface Options {
  authToken?: string;
  ip?: string;

  /**
   * The prefix for the urls of the areas your MemriseAPI
   * instance deals with e.g. '/web/payments'
   *
   * Must start with '/'
   */
  endpointPrefix: string;
}

export default abstract class MemriseApi {
  private authToken?: string;
  private ip?: string;
  private endpointPrefix?: string;

  constructor({ ip = undefined, authToken = undefined, endpointPrefix }: Options) {
    this.authToken = authToken;
    this.ip = ip;
    this.endpointPrefix = endpointPrefix;
  }

  private async call<Data extends Record<string, unknown>>(
    endpoint: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT',
    body?: BodyInit,
  ): Promise<Data> {
    const cookies = { [COOKIES_KEYS.authToken]: this.authToken };
    const options = { method, cookies, ip: this.ip, body };

    const response = await memriseApiFetch(`${this.endpointPrefix}${endpoint}`, options);
    return parseResponse<Data>(response, `${this.endpointPrefix}${endpoint}`);
  }

  protected async get<
    ResponseData extends Record<string, unknown>,
    QueryParams extends Record<string, unknown> = Record<string, never>,
  >(endpoint: string, params?: QueryParams): Promise<ResponseData> {
    const query = this.getQuery(params);
    return this.call<ResponseData>(`${endpoint}${query}`, 'GET', undefined);
  }

  private getQuery<QueryParams extends Record<string, unknown>>(params?: QueryParams) {
    return typeof params !== 'undefined' ? `?${stringify(params)}` : '';
  }

  protected async post<
    ResponseData extends Record<string, unknown>,
    BodyObject extends Record<string, unknown> = Record<string, never>,
    QueryParams extends Record<string, unknown> = Record<string, never>,
  >(
    endpoint: string,
    {
      bodyObject,
      queryParams,
    }: {
      bodyObject?: BodyObject;
      queryParams?: QueryParams;
    },
  ): Promise<ResponseData> {
    const query = this.getQuery(queryParams);
    const body = JSON.stringify(bodyObject);
    return this.call<ResponseData>(`${endpoint}${query}`, 'POST', body);
  }

  protected async put<
    ResponseData extends Record<string, unknown>,
    BodyObject extends Record<string, unknown> = Record<string, never>,
    QueryParams extends Record<string, unknown> = Record<string, never>,
  >(
    endpoint: string,
    {
      bodyObject,
      queryParams,
    }: {
      bodyObject?: BodyObject;
      queryParams?: QueryParams;
    },
  ): Promise<ResponseData> {
    const query = this.getQuery(queryParams);
    const body = JSON.stringify(bodyObject);
    return this.call<ResponseData>(`${endpoint}${query}`, 'PUT', body);
  }

  /* istanbul ignore next: untested branch of code, please test */
  protected async delete<
    ResponseData extends Record<string, unknown>,
    BodyObject extends Record<string, unknown> = Record<string, never>,
    QueryParams extends Record<string, unknown> = Record<string, never>,
  >(
    endpoint: string,
    {
      bodyObject,
      queryParams,
    }: {
      bodyObject?: BodyObject;
      queryParams?: QueryParams;
    } = {},
  ): Promise<ResponseData> {
    const query = this.getQuery(queryParams);
    const body = JSON.stringify(bodyObject);
    return this.call<ResponseData>(`${endpoint}${query}`, 'DELETE', body);
  }
}
